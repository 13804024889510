/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { bool, func, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import loadable from '@loadable/component';

import DesktopMobile from '../../../helpers/DesktopMobile/DesktopMobile';
import uniqueId from '../../../helpers/contentstack/uniqueId';
import useUIDQueryConditionally from '../../../helpers/hooks/useUIDQueryConditionally';
import GenericSkeleton from '../../GraphqlComponents/GraphqlSkeletonComponents/GenericSkeleton';
import ComponentErrorBoundary from '../../GraphqlComponents/EnterpriseID/Common/ComponentErrorBoundary/ComponentErrorBoundary';
import noop from '../../../helpers/noop';

const ConnectedBannerV2 = loadable(() => import(/* webpackChunkName: "ConnectedBannerV2" */'../BannerV2/BannerV2'));
const GenericBannerResponsive = loadable(() => import(/* webpackChunkName: "GenericBannerResponsive" */'./FullResponsive/GenericBannerResponsive'));
const GenericBannerMobile = loadable(() => import(/* webpackChunkName: "GenericBannerMobile" */'./Mobile/GenericBannerMobile'));
const GenericBannerDesktop = loadable(() => import(/* webpackChunkName: "GenericBannerDesktop" */'./Desktop/GenericBannerDesktop'));
const SideBySideBanner = loadable(() => import(/* webpackChunkName: "SideBySideBanner" */'../SideBySideBanner/SideBySideBanner'));
const PersonalizedUserGreetingContainer = loadable(() => import(/* webpackChunkName: "PersonalizedUserGreetingContainer" */'../../GraphqlComponents/GraphqlCommonComponents/PersonalizedUserGreeting/PersonalizedUserGreetingContainer'));
const OneClickPurchaseContainer = loadable(() => import(/* webpackChunkName: "OneClickPurchaseContainer" */ '../../GraphqlComponents/GraphqlCommonComponents/OneClickPurchase/OneClickPurchaseContainer'));

const styles = (theme) => ({
    block: {
        position: 'relative',
        margin: '0 auto 32px',
        [theme.breakpoints.down(1024)]: {
            margin: '0 auto 16px',
        },
    },
});

const GenericBanner = ({
    data: blockData, classes, isCollectionPage, salesforceResponse, setShowSkeleton,
}) => {
    const { data, loading } = useUIDQueryConditionally({
        data: blockData,
        includeRefs: ['reference'],
    });
    const contentTypeUid = data?.reference?.[0]?.['_content_type_uid'];
    if (contentTypeUid === 'banner_v2') return <ConnectedBannerV2 data={data} />;
    if (contentTypeUid === 'side_by_side_image_banner' || contentTypeUid === 'side_by_side_image_banner_advanced') return <SideBySideBanner data={data} />;
    if (contentTypeUid === 'personalized_login_modules') return <PersonalizedUserGreetingContainer blockData={data} isCollectionPage={isCollectionPage} />;
    if (contentTypeUid === 'one_click_purchase_module') {
        return (
            <ComponentErrorBoundary function="OneClickPurchaseContainer" module="OneClickPurchase">
                <OneClickPurchaseContainer blockData={data} />
            </ComponentErrorBoundary>
        );
    }

    if (data?.reference?.[0]?.reference?.[0]) {
        const unique_selector = uniqueId(data.reference[0].unique_selector);
        const deviceType = data.reference[0].device;
        const cmsuid = data.reference[0].uid || null;

        switch (deviceType) {
            case 'Mobile':
                return (
                    <DesktopMobile mobile={() => {
                        if ((loading) && blockData?.mobile_skeleton) {
                            return <GenericSkeleton height={blockData?.mobile_skeleton_height || blockData?.skeleton_height} />;
                        }
                        return (
                            <div className={`genericbanner_block ${classes.block}`} data-uniqueid={unique_selector} cmsuid={cmsuid}>
                                <GenericBannerMobile data={data} salesforceResponse={salesforceResponse} setShowSkeleton={setShowSkeleton} />
                            </div>
                        );
                    }}
                    />
                );
            case 'Desktop':
                return (
                    <DesktopMobile
                        breakpoint="1024"
                        desktop={() => {
                            if ((loading) && blockData?.desktop_skeleton) {
                                return <GenericSkeleton height={blockData?.skeleton_height} />;
                            }
                            return (
                                <div className={`genericbanner_block ${classes.block}`} data-uniqueid={unique_selector} data-testid="genericbanner_block" cmsuid={cmsuid}>
                                    <GenericBannerDesktop data={data} salesforceResponse={salesforceResponse} setShowSkeleton={setShowSkeleton} />
                                </div>
                            );
                        }}
                    />
                );

            case 'Full Responsive':
                if (loading) {
                    return (
                        <DesktopMobile
                            desktop={() => blockData?.desktop_skeleton && <GenericSkeleton height={blockData?.skeleton_height} />}
                            mobile={() => blockData?.mobile_skeleton && <GenericSkeleton height={blockData?.mobile_skeleton_height} />}
                        />
                    );
                }
                return (
                    <div className={`genericbanner_block ${classes.block}`} data-uniqueid={unique_selector} data-testid="genericbanner_block" cmsuid={cmsuid}>
                        <GenericBannerResponsive data={data} salesforceResponse={salesforceResponse} setShowSkeleton={setShowSkeleton} />
                    </div>
                );

            case 'Both':
            default:
                return (
                    <div className={`genericbanner_block ${classes.block}`} uniqueId={unique_selector} cmsuid={cmsuid}>
                        <DesktopMobile
                            breakpoint="600"
                            desktop={() => {
                                if ((loading) && blockData?.desktop_skeleton) {
                                    return <GenericSkeleton height={blockData?.skeleton_height} />;
                                }
                                return <GenericBannerDesktop data={data} salesforceResponse={salesforceResponse} setShowSkeleton={setShowSkeleton} />;
                            }}
                            mobile={() => {
                                if ((loading) && blockData?.mobile_skeleton) {
                                    return <GenericSkeleton height={blockData?.mobile_skeleton_height || blockData?.skeleton_height} />;
                                }
                                return <GenericBannerMobile data={data} salesforceResponse={salesforceResponse} setShowSkeleton={setShowSkeleton} />;
                            }}
                        />
                    </div>
                );
        }
    }
    return <></>;
};

GenericBanner.propTypes = {
    classes: object.isRequired,
    data: object.isRequired,
    isCollectionPage: bool,
    salesforceResponse: object,
    setShowSkeleton: func,
};

GenericBanner.defaultProps = {
    isCollectionPage: false,
    salesforceResponse: {},
    setShowSkeleton: noop,
};

export default withStyles(styles)(GenericBanner);
